<template>
  <div class="worker-list">
    <div 
      v-for="(worker, index) in workers" 
      :key="index" 
      class="worker-item"
      :style="{ backgroundColor: worker.color }"
    >
      <span class="worker-name">{{ worker.name }}</span>
      <span class="service-count">{{ worker.serviceCount }}</span>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onBeforeMount } from 'vue';
import store from "@/store";
import { getDateBrazil} from "@/utils";
export default {
  name: 'WorkerList',
  props: {
    users: {
        type: Array,
        default: () => []
    },
  },

  setup(props) {

    const processList = computed(
      () => store.state.processList || []
    );
    
    const fixedColors = [
      '#FF0000', // Vermelho
      '#FFFF00', // Amarelo
      '#000000', // Preto
      '#32CD32', // Verde Claro
      '#006400', // Verde Escuro
      '#4169E1', // Azul claro
      '#0000FF', // Azul escuro
      '#363636', // preto cinza
      '#FF9900', // Amarelo Escuro
    ];

    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const workers = ref([]);
    const users = ref([]);
    
    const calculateServiceCount = (userId) => {
      return processList.value.filter(process => process.productor === userId).length;
    };

    const transformUsers = () => {
      return users.value
        .filter(user => user.type_user === 'I')
        .map(user => ({
          name: user.name,
          serviceCount: calculateServiceCount(user.id),
          color: generateRandomColor()
        }));
    };

    workers.value.forEach((worker, index) => {
      if (index < fixedColors.length) {
        worker.color = fixedColors[index];
      } else {
        worker.color = generateRandomColor(); 
      }
    });

    watch(() => props.users, (newValue) => {
        users.value = newValue;
        workers.value = transformUsers();
    });
    watch(() => store.state.sendPrint,  async () => {
        await store.dispatch("setProcessListCurrent", getDateBrazil());
        workers.value = transformUsers();
    });

    onBeforeMount(async () => {
        await store.dispatch("setProcessListCurrent", getDateBrazil());
    });

    return { workers , processList};
  }
};
</script>

<style scoped>
.worker-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15px;
}

.worker-item {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 0 2px;
  flex: 1;
  justify-content: space-between;
  color: white;
  font-size: 10px;
}

.worker-name {
  font-weight: bold;
}

.service-count {
  font-size: 12px;
}
</style>
