import { createStore } from 'vuex';
import { getClients, getProcessAllFind, getProcesses, 
    getToken, getUsers, login, getUser,
    getProcessDesign,
    getMaterials,
    getListMaterials,
    getProcessExist,
    getMachines,
    getListMachines,
    getProcessesMachine,
    getProcessPendding,
    getRooms,
    createRoom,
    getMessageNotViewAndLast,
    getMessages,
    setMessageView,
    getProcessesClient,
    getStock,
    getStockHistory,
    getLackMaterialForDate,
    getPointForDate,
    getPointAllFindUser,
    getPointVerifitedTodayUser
} from '../http';
import { sortProcess, getDateBrazil } from "../utils";

export default createStore({
    state () {
        return {
            processList : [],
            clientsList: [],
            materialList: [],
            stockList: [],
            lackMaterialList: [],
            pointsAllList: [],
            pointsList: [],
            stockListHistory: [],
            materialListView: [],
            machineList: [],
            machineListView: [],
            commentsCurrent: [],
            users: [],
            executeProcessesPrinter: [],
            executeProcessesLecturer: [],
            executeProcessesDelivery: [],
            listProcessesOrders: [],
            rooms: [],
            messagesCurrent: [],
            userViewEdit: {},
            userCurrent: {},
            register: {},
            processOriginalEdit: {},
            typeEventMaterial: {type: null, material: null, nameMaterial: null}, // E = edit, D - delete
            typeEventMachine: {type: null, machine: null, nameMachine: null}, // E = edit, D - delete
            typeEventStock: {type: null, stock: null, nameStock: null}, // H- historic, D - delete
            typeEventPoint: {type: null, point: {}}, // E- historic, D - delete
            openE:  false,
            openEclient: false,
            openEprocess: false,
            existProcess: false,
            observeChat: false,
            showModalStart: false,
            sendPrint: false,
            pagePrevious: null,
            countGeralMessage: 0,
            roomChannelSelected: '',
        }
    },
    mutations: {
        setUser(state, data){
            state.userCurrent = data
        },

        setTokenRegister(state, data){
            state.register = data;
        },

        setProcessList(state, data){
            state.processList = sortProcess(data);
        },

        setClientList(state, data){
            state.clientsList=data;
        },

        setUsers(state, data){
            state.users = data;
        },

        setProcessPrinterList(state, data){       
            state.executeProcessesPrinter = sortProcess(data);
        },

        setProcessLecturerList(state, data){       
            state.executeProcessesLecturer = sortProcess(data);
        },

        setUserViewEdit(state, data){
            state.userViewEdit = data;
        },

        setOpenEdit(state){
            state.openE = state.openE ? false : true;
        },

        setOpenEditClient(state){
            state.openEclient = state.openEclient ? false : true;
        },

        setOpenEditProcess(state){
            state.openEprocess = state.openEprocess ? false : true;
        },

        setSendPrint(state){
            state.sendPrint = state.sendPrint ? false : true;
        },

        setObserveChat(state, data){
            state.observeChat = data;
        },

        setPagePrevious(state, data){
            state.pagePrevious = data;
        },

        setProcessesDelivery(state, data){ 
            state.executeProcessesDelivery = data;
        },

        setProcessOriginalEdit(state, data){
            state.processOriginalEdit = data;
        },

        setEditOrDeletePoint(state, data){
            state.typeEventPoint = {type: null, point: {}}
            state.typeEventPoint = data
        },

        addNewProcess(state, data){
            let checkProcessExisted = state.processList?.find(process => process.id == data?.id);
            let checkProcessExecuteExisted = state.executeProcessesPrinter?.find(process => process.id == data?.id);
            let checkProcessOrder = state.listProcessesOrders?.find(process => process.id !== data?.id && process.client == data?.client);

            if(data?.materials){
                if(!state.materialListView?.find(m => m.id === data?.materials?.id)){
                    state.materialListView?.push(data?.materials)
                }
            }
            
            if(data?.updated){

                let index = state.processList.findIndex(process => process.id === data?.id);
                if (index !== -1) {
                    state.processList[index] = data;
                }
                return;
            }

            if(data?.deleted){
                state.processList = state.processList?.filter(process => process.id != data?.id)
                return;
            }

            if( !checkProcessExisted ){
                state.processList?.unshift(data);
            }
            if( !checkProcessOrder ){
                state.listProcessesOrders?.unshift(data);
            }

            if( state.executeProcessesPrinter?.length > 0 && !checkProcessExecuteExisted ) {
                state.executeProcessesPrinter?.unshift(data);
            }

            if(data?.delivery){
                state.executeProcessesDelivery = {reflash: true};
            } 
        },

        updatedProcess(state, data) {
            let process = state.processList?.find(process => process.id == data?.id);
            let processLecturer = state.executeProcessesLecturer?.find(process => process.id == data?.id);
            let processPrinter = state.executeProcessesPrinter?.find(process => process.id == data?.id);
            let processOrder = state.listProcessesOrders?.find(process => process.id == data?.id);

            if (process) {
                process.stage = data?.stage
                process.machine = data?.machineType
            }

            if (processOrder) {
                processOrder.stage = data?.stage
            }

            if (processLecturer) {
                processLecturer.stage = data?.stage
                processLecturer.machine = data?.machineType
            }

            if (processPrinter) {
                processPrinter.stage = data?.stage
                processPrinter.machine = data?.machineType

                if(processPrinter.stage === 'I'){
                    state.executeProcessesPrinter = state.executeProcessesPrinter?.filter(process => process.id !== data?.id);
                }
            }

            if(data?.delivery){
                state.executeProcessesDelivery = {reflash: true};
            }   

        },

        setMaterial(state, data){

            if(!state.materialListView?.find(item=> item.id == data?.id)){
                state.materialListView?.unshift(data);
            }    
            if(data?.delete){
                state.materialList = state.materialList?.filter((item)=> item.id !== data.id);
                return;
            }

            if(!state.materialList?.length){
                state.materialList = data;
                return;
            }

            const materialFind = state.materialList?.find((item)=> item.id === data?.id );
            
            if(materialFind){
                materialFind.name = data?.name;
                return
            }

            state.materialList?.unshift(data); 
            
        },

        setMachine(state, data){

            if(!state.machineListView?.find(item=> item.id == data?.id)){
                state.machineListView?.unshift(data);
            }    
            if(data?.delete){
                state.machineList = state.machineList?.filter((item)=> item.id !== data.id);
                return;
            }

            if(!state.machineList?.length){
                state.machineList = data;
                return;
            }

            const machineFind = state.machineList?.find((item)=> item.id === data?.id );
            
            if(machineFind){
                machineFind.name = data?.name;
                return
            }

            state.machineList?.unshift(data); 
            
        },

        setMaterialListView(state, data){
            state.materialListView = data;
        },

        setMachineListView(state, data){
            state.machineListView = data;
        },

        setTypeEventMaterial(state, data){
            state.typeEventMaterial = data
        },

        setTypeEventMachine(state, data){
            state.typeEventMachine = data
        },

        setTypeEventStock(state, data){
            state.typeEventStock = data
        },

        setExistProcess(state, data){
            state.existProcess = data;
        },

        setRooms (state, data) {
            let roomFind = state.rooms.find(room => room.id == data.id);
            if (!roomFind) {
                state.rooms.push(data);
            }else{
                roomFind = data;
            }
        },

        setInfoRoom (state, data) {
            let roomFind = state.rooms.find(room => room.id == data.id);
            if (!roomFind) {
                return;
            }
            Object.assign(roomFind , data);
        },

        setMessages(state, data){
            state.messagesCurrent = data;
        },

        setUpdatedChatCurrent(state, data) {
            const messageExist = state.messagesCurrent?.find( msg => msg.id === data.id );
            if( messageExist ) return;

            data.timestamp = data.createdAt;
            state.messagesCurrent.push(data);

            setTimeout(() => {
                const element = document.getElementById('box-message');
                if(element) element.scrollTop = element?.scrollHeight;
            }, 10);
        },

        updateViewMessage(state, data) {
            const room = state.rooms.find((item)=> item.id === data?.roomId);
            const message =  state.messagesCurrent?.find((item)=> item.id === data?.messageId);
            if(!message || !room) return;
            room.countMessage --;
            state.countGeralMessage --;
            message.view = data?.view;
        },

        notifyMessage (state, data) {  
            const room = state.rooms.find(item=> item.id == data?.roomId);
            const message  = state.messagesCurrent?.find(item=> item.id == data?.messageId);
            if(!room)return;
            
            if(!message){

                if(room && (!room?.countMessage || !room?.countMessage === 0)){
                    room.countMessage = 1;
                }else{
                    room.countMessage += 1;
                }
                
                state.countGeralMessage += 1;
                room.lastMessage = data?.message?.content;
                room.lastUpdated = data?.message?.createdAt;
                return;
            }else if(!message.view?.includes(state.userCurrent.id)){
                room.countMessage += 1;
                state.countGeralMessage += 1;
                room.lastMessage = data?.message?.content;
                room.lastUpdated = data?.message?.createdAt;
                return;
            }
        },

        setCountGeralMessage(state) {
            let  count = 0;
            for(let room of state.rooms){
                if(room.countMessage >= 0 ) 
                    count += room.countMessage
            }
            state.countGeralMessage = count;
        },

        setReOrderRoom(state) {
            const sortByDateDescending = (arr) => {
                arr.sort((a, b) => {
                    const dateA = new Date(a.orderUpdated);
                    const dateB = new Date(b.orderUpdated);
                    return dateB - dateA; 
                });
                return arr;
            }
            state.rooms = sortByDateDescending(state.rooms);
        },

        clearMessagesCurrent(state){
            state.messagesCurrent= [];
        },

        setRoomChannelSelected(state, data){
            state.roomChannelSelected = data;
        },
        
        setListProcessesOrders(state, data){
            state.listProcessesOrders = data;
        },

        setStock(state, data){
            state.stockList = data;
        },

        setStockHistory(state, data){
            state.stockListHistory = data;
        },

        setLackMaterial(state, data){
            state.lackMaterialList = data;
        },

        setPointAll(state, data){
            state.pointsAllList = data;
        },

        setPoint(state, data){
            state.pointsList = data;
        },

        setShowModalStart(state, data){
            state.showModalStart = data;
        }

    },
    actions: {
        async setUserCurrent({ commit }, data) {
            const loginUser = await login({email: data?.email, password: data?.password, tokenAuth: data?.tokenAuth});
            if(!loginUser.message && !sessionStorage.getItem('token')){
                sessionStorage.setItem("token", loginUser?.token);
            }
            commit('setUser', loginUser);
            return loginUser;
        },

        async tokenRegister({commit}, data){
            const requestToken = await getToken(data);
            if(requestToken?.permission){
                commit('setTokenRegister', requestToken);  
            }
            return requestToken;
        },

        async setProcessListCurrent({commit}, date){
            const responseProcess = await getProcesses(date);
            commit('setProcessList', responseProcess);
            return responseProcess || [];
        },

        async setMachineListHistory({commit, state}, {date, machine}){
            const responseProcess = await getProcessesMachine(date, machine || state.typeEventMachine.machine);
            commit('setProcessList', responseProcess);
            return responseProcess;
        },

        async setProcessListCurrentDesigner({state, commit}, date){
            const responseProcess = await getProcessDesign(state.userCurrent.id, date);
            commit('setProcessList', responseProcess || []);
            return responseProcess;
        },

        async setProcessListLecturerPrinterCurrent({commit}, {data, status, date}){
            const responseProcess = await getProcessAllFind(data, status, date);

            if(data?.stage[0] == 'I'){
                commit('setProcessLecturerList', responseProcess);
            }

            if(data?.stage[0] == 'D'){  
                commit('setProcessPrinterList', responseProcess);
            }

            return responseProcess;
        },

        async setClientListCurrent({commit}){
            const responseClient = await getClients();
            commit('setClientList', responseClient);
            return responseClient;
        },

        async getUsers({commit}){
            const resposeUsers = await getUsers();
            commit('setUsers', resposeUsers);
            return  resposeUsers;
        },

        async getUser({commit}, data){
            const  reponseUser = await getUser(data);
            if(!reponseUser.message){
                commit("setUserViewEdit", reponseUser);
            }
            return  reponseUser;
        },

        setNewProcess({commit}, data){
            if(data){
                commit("addNewProcess", data);
                return;
            }
        },

        setUpdatedProcess({commit}, data){
            if(data){
                commit("updatedProcess", data);
            }
        },

        setMaterialList({commit}, data) {
            commit('setMaterial', data);
        },

        setMachineList({commit}, data) {
            commit('setMachine', data);
        },

        async setMaterialListCurrent({commit}){
            const requestMaterials = await getMaterials();

            if(!requestMaterials?.message){
                commit('setMaterial', requestMaterials);
            }
            
            return requestMaterials;
        },

        async setMachineListCurrent({commit}){
            const requestMachines = await getMachines();

            if(!requestMachines?.message){
                commit('setMachine', requestMachines);
            }
            
            return requestMachines;
        },

        async getMaterialListView({commit}, data){
            const requestMaterials = await getListMaterials(data);
            
            if(!requestMaterials?.message){
                commit('setMaterialListView', requestMaterials);
            }
            
            return requestMaterials;
        },

        async getMachineListView({commit}, data){
            const requestMachines = await getListMachines(data);
            
            if(!requestMachines?.message){
                commit('setMachineListView', requestMachines);
            }
            
            return requestMachines;
        },

        async getExistProcess({commit, state}, data){
            const requestStatus = await getProcessExist(data ? state.userCurrent.id : null);
            commit( 'setExistProcess', requestStatus?.status );
            return requestStatus;
        },

        async getProcessesDelivery({commit}){
            const requestProcess = await getProcessPendding();
            commit("setProcessesDelivery", requestProcess)
            return requestProcess;
        },

        async getRooms({commit, state}){
            const requestUsers = await getUsers();
            const requestRooms = await getRooms(state.userCurrent.id);

            if(
                requestUsers?.status == false || 
                requestRooms?.status == false
            ){ 
                commit("setRooms", []);
                return [];
            }
            const createRoomInUser = async (users) => {
                for (const user of users || []){
                    if(user.id != state.userCurrent.id){
                        let payload = {
                            name: `{"${user.id}":"${user.name}","${state.userCurrent.id}":"${state.userCurrent.name}"}`,
                            members: `[${user.id},${state.userCurrent.id}]`
                        }
                        let responseRoom = await createRoom(payload);
                        if(responseRoom?.id){                            
                            responseRoom.name = user.name;
                            responseRoom.members = JSON.parse(responseRoom.members);
                            if(responseRoom.members?.includes(state.userCurrent.id)){
                                commit("setRooms",responseRoom);
                            }
                            
                        }
                    }
                }
            }

            if(requestRooms?.length == 0){
               await createRoomInUser(requestUsers);
            }else{
                
                let idExistInRoom = [];
                for (const room of requestRooms || []){
                    const members = JSON.parse(room.members);
                    for(const member of members || []){
                        if(!idExistInRoom.includes(member))
                            idExistInRoom.push(member)
                    }
                }
                const newUserForRoom = requestUsers.filter( user => !idExistInRoom.includes(user.id) );
                if(newUserForRoom.length > 0){
                    await createRoomInUser(newUserForRoom);
                }

                for(let room of requestRooms || []){
                    let nameSelected = ''
                    let names = JSON.parse(room.name);
                    for (let key in names) {
                        if(key != state.userCurrent.id){
                            nameSelected = key;
                        }
                    }
                    room.name = requestUsers?.find(user => user.id == nameSelected)?.name || '';
                    room.members = JSON.parse(room.members);
                    if(room.members?.includes(state.userCurrent.id) && room.name){
                        commit("setRooms",room);
                    }
                }
            }
        },

        async getInfoRooms({state, commit}){
            for(let room of state.rooms || []){
                const requestInfo = await getMessageNotViewAndLast(room.id, state.userCurrent.id);
                if(requestInfo?.newMessages ||  requestInfo?.lastMessage){
                    room.countMessage = requestInfo?.newMessages || 0;
                    room.lastMessage = requestInfo?.lastMessage.content;
                    room.lastUpdated = requestInfo?.lastMessage.createdAt;
                    commit("setInfoRoom",room);
                }
            }
            commit('setCountGeralMessage');
            commit("setReOrderRoom");
        },

        async setUpdatedChat({commit, state}, data){
            commit('setUpdatedChatCurrent', data);
            let viewsExist = data?.view || [];
            if(!viewsExist?.includes(state.userCurrent.id)){
              viewsExist.push(state.userCurrent.id);
              await setMessageView(data.id, {view: JSON.stringify(viewsExist)})
                .then( (data) => {
                  if(data.status){
                    commit('updateViewMessage', {roomId: data.roomId, messageId: data.id, view: viewsExist});
                  }
                });
            }
        },

        async setMessageChat({commit}, data){
            const messages = await getMessages(data);
            commit('setMessages', messages);
        },

        upadatedInfoRoom({commit, state}, data){
            const room = state.rooms.find(item=> item.id == data?.roomId);
            if(room){
                room.lastMessage = data?.content;
                room.lastUpdated = data?.createdAt;
                room.orderUpdated = data?.createdAt;   
            
                commit("setReOrderRoom");
            }
        },

        async getListProcessesOrders({commit}, clientId){
            let requestOrder = await getProcessesClient(clientId);

            if(requestOrder?.message){
                requestOrder = [];
            }

            commit('setListProcessesOrders', requestOrder);
            return requestOrder;
        },

        async setStockListCurrent({commit}){
            const requestStock = await getStock();

            if(!requestStock?.message){
                commit('setStock', requestStock);
            }
            
            return requestStock;
        },

        async setStockListHistoryCurrent({commit, state}){
            const requestStockHistory = await getStockHistory(state.typeEventStock.stock);

            if(!requestStockHistory?.message){
                commit('setStockHistory', requestStockHistory);
            }
            
            return requestStockHistory;
        },

        async setLackMaterialCurrent({commit}, {dateStart, dateEnd}){
            const requestLackMaterial = await getLackMaterialForDate(dateStart, dateEnd);

            if(!requestLackMaterial?.message){
                commit('setLackMaterial', requestLackMaterial);
            }
            
            return requestLackMaterial;
        },

        async setPointAllCurrent({commit}, date){
            const requestPointAll = await getPointForDate(date);

            if(!requestPointAll?.message){
                commit('setPointAll', requestPointAll);
            }
            
            return requestPointAll;
        },

        async setPointUserCurrent({commit,state}){
            const requestPoint = await getPointAllFindUser(state.userCurrent.id);

            if(!requestPoint?.message){
                commit('setPoint', requestPoint);
            }
            
            return requestPoint;
        },

        async setShowModalStartCurrent({state}){
            const requestVeritPoint = await getPointVerifitedTodayUser(state.userCurrent.id, getDateBrazil());
            sessionStorage.setItem(`modalPoint${state.userCurrent.id}`, requestVeritPoint?.status );
            
            if(!sessionStorage.getItem(`pontoNext${state.userCurrent.id}`)){
                sessionStorage.setItem(`pontoNext${state.userCurrent.id}`, 1); 
            }

            location.reload();
        },

    },
    getters: {
        getUser(state) {
            return state.userCurrent;
        },
        getTokenRegister(state){
            return state.register; 
        },
        getProcessesPrinter(state){
            return sortProcess(state.executeProcessesPrinter)
        },
        getProcessesLecturer(state){
            return sortProcess(state.executeProcessesLecturer)
        },
        getProcesses(state){
            return state.processList;
        }
    }

})